@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--foreground-rgb: 0, 0, 0;
	--background-start-rgb: 214, 219, 220;
	--background-end-rgb: "#09101D";
	--hover-text-effect: "#121A27";
}

@media (prefers-color-scheme: dark) {
}
html {
	scroll-behavior: smooth;
}
body {
	background: var(--background-end-rgb);
}

::-webkit-scrollbar {
	width: 8px; /* Adjust this value as needed for non-small devices */
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 4px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f155;
}
/* navbar section */
.navbar {
	position: sticky;
	top: 0;
	z-index: 999;
}
.nav-bar-links > ul > li {
	cursor: pointer;
	margin-right: 2rem;
	font-weight: 500;
	font-size: 15px;
}
.mobile-compe {
	width: 700px;
}

.nav-bar-links > ul > li:hover {
	color: #b8babe;
}

/* video component design */
.video-container {
	/* height: 800px; */
	position: relative;
	/* display: flex; */
	justify-content: center;
	align-items: center;
}
.top-color {
	height: 720px;
	width: 100%;
	position: absolute;
	background: linear-gradient(to right, #09101d, #09101db6 50%, #09101d);
}
.btn-primary:hover {
	background-color: #d8251cb8;
	box-shadow: #fa655d 0px 5px 15px;
}
#alert-dialog-slide-description {
	margin-top: 5px;
}
.bottom-nav {
	position: absolute;
	bottom: 0;
	/* right: 50%; */
	margin-top: 10rem;
	width: 100%;
	margin: 0 auto;
}

.hero-more {
	justify-content: left;
}

.empty-img-cover {
	position: absolute;
	background: linear-gradient(to right, #09101d, #09101dca 50%, #09101d);
}

.top-content {
	position: absolute !important;
	top: 0;
	margin-top: 2rem;
}

/* list of elements */
.list-elements > ul > li {
	display: flex;
	align-items: center;
	margin-top: 1rem;
	color: #9b9b9b;
}

/* services card effect */
.hover-box-shadow:hover {
	box-shadow: #d8251c54 0px 30px 60px -12px inset,
		#9b9b9b 0px 18px 36px -18px inset;
}

/* in */
.demo-bottom-overlay {
	height: 363px;
	background: linear-gradient(to right, #09101d, #09101d65 50%, #09101d65);
}

.years {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.years-number {
	position: absolute;
	top: 40%;
	left: 100%;
	margin-left: 1.5rem;
	transform: translate(-50%, -50%);
}

/* footer section */
.icon {
	display: flex;
	align-items: center;
	margin-top: 15px;
}
.icon-fy {
	margin-top: 4rem;
}
.icon > p {
	margin-left: 10px;
	font-size: 0.8rem;
	font-weight: 500;
	cursor: pointer;
}
.icon > p:hover {
	text-decoration: underline;
}

/* unique BTN */
.unique-btn {
	position: absolute;
	top: 15%;
	left: 2%;
	background-color: #09101d;
	padding: 0;
	font-weight: 600;
}
.unique-btn:hover {
	text-decoration: underline;
}
.empty-div-button {
	border-radius: 100%;
	border: 2px solid #d8261c;
}
.empty-div-button:hover {
	box-shadow: rgba(148, 23, 23, 0.19) 0px 10px 20px,
		rgba(255, 56, 56, 0.23) 0px 6px 6px;
	cursor: pointer;
}

/* annimating the text
 */
@keyframes slideIn {
	0% {
		opacity: 0;
		transform: translateY(100%);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.animate-slideIn {
	animation: slideIn 0.5s ease forwards;
}

/* tabs section select option */
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
	color: #9b9b9b !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
	color: #d8261c !important;
}
.css-10d9dml-MuiTabs-indicator {
	background-color: #d8261c !important;
}

.css-1q2h7u5.Mui-selected {
	color: #d8261c !important;
}

.css-1q2h7u5 {
	color: #9b9b9b !important;
}

.css-7ozjwe {
	background-color: #d8261c !important;
}

/* images galery */
.container {
	width: 220px;
	height: 100px;
	position: relative;
	overflow: hidden;
	background-color: #fff;
	border-radius: 5px;
	cursor: pointer;
}

.image {
	width: 100%;
	object-fit: cover;
	height: 100%;
	transition: box-shadow 0.3s ease;
}

.container:hover .image {
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.container:hover .image:hover {
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5), 0 0 0 1px red;
}
.css-gbsiyo {
	height: 360px !important;
}
.mobile-menu-display {
	display: none;
}
.mobile-tabs-display {
	display: none;
}

/* Annimation slider */
.slide-in {
	opacity: 0;
	transform: translateY(50%);
	transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.slide-in.slide-in-active {
	opacity: 1;
	transform: translateY(0%);
}
.auto-type {
	color: #d8261c !important;
}
.competition-color-over-lay {
	background: linear-gradient(to right, #09101d72, #09101d8b 50%, #09101d);
}

/* slider image */

.slider-img {
	background-color: #d8251c2c;
	border-radius: 5px;
	border: 1px solid #d8261c;
}
.img-slider {
	height: 150px;
	width: 150px;
	object-fit: cover;
	border-radius: 100%;
	border: 3px solid #09101d72;
	position: absolute;
	left: 40%;
}
.next-prev-btn {
	position: absolute;
	justify-content: space-between;
}
/* Media querry */
@media (max-width: 769px) {
	::-webkit-scrollbar {
		width: 1px;
	}
	.mobile-menu-display {
		display: block;
	}
	.mobile-nav-menu {
		display: none;
	}
	.img-slider {
		left: 30%;
	}
	.icon-logo-mobile {
		height: 30px;
		margin-left: 5px;
		object-fit: contain;
		width: 100px;
	}
	.mobile-position-hero {
		margin-top: 1rem !important;
		width: 100% !important;
	}
	.hero-welcome-message {
		font-size: 2rem;
	}
	.video-mobile-size {
		height: 655px;
		object-fit: cover !important;
	}
	.top-color {
		height: 655px;
	}
	.text-sizing-mobile {
		width: 100%;
	}
	.text-explain-mobile {
		font-size: 1rem;
		color: #b7b3b3;
	}
	.mobile-adv-div {
		display: block;
		width: 100%;
		padding: 5px;
	}
	.mobile-adv-div > p {
		width: 100%;
	}
	.mobile-adv-div > h1 {
		color: #d8261c;
	}
	.mt-mobile-scren {
		margin-top: 55rem;
	}
	.mobile-compe {
		width: 100%;
	}
	.display-block-button {
		display: block;
		color: #fff;
		font-weight: 600;
		margin-top: 2rem;
	}

	/* Mobile Design */
	.mobile-design-stem {
		flex-direction: column;
	}
	.toy-car-display {
		display: none;
	}
	.top-content {
		margin-top: 0;
	}
	.empty-img-cover {
		height: 23rem;
	}

	.img-cover-stem {
		height: 33rem !important;
	}

	/* R and D styling */
	.randd {
		margin-top: 2rem;
	}
	.image-randd {
		margin-left: 1px;
	}
	.title-space-left {
		margin-left: 10px;
	}
	.title-services {
		width: 100%;
	}
	.mobile-design-tabs {
		display: none !important;
	}

	.css-19kzrtu {
		padding: 10px !important;
	}
	.img-position-grid {
		width: 100% !important;
	}
	.mobile-tabs-display {
		display: block;
	}

	/* Who we are */
	.who-we-are-video {
		height: 390px;
		object-fit: cover;
	}
	.who-we-are {
		position: absolute;
	}
	.who-we-mobile {
		margin-left: 0px;
	}
	.demo-bottom-overlay {
		height: 390px;
	}
	.years-number {
		font-size: 1.6rem;
	}
	.years-number > span {
		font-size: 1rem;
		margin-left: 5px;
	}
	.who-we-are-icon {
		width: 60px;
	}
	.numbers-who-we-are {
		width: 60%;
	}
	.mobile-partners-cards {
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		gap: 0.5rem;
		padding: 5px;
	}
	.mobile-footer-opt {
		flex-direction: column;
		justify-content: space-between;
		padding: 5px;
		height: 650px;
	}
	.map-direction-footer {
		margin-left: 0px;
	}
	.mobile-map {
		width: 100%;
	}
	.card-list-team {
		flex-direction: column;
	}
}
.background-img {
	position: absolute;
	top: 20%;
	left: 30%;
	z-index: 0;
}
.team-box-card:hover {
	box-shadow: #d8251c76 0px 8px 24px;
}
